import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLiff } from "react-liff";

import MainLayout from "layouts/MainLayout";
import PageLoader from "./components/loader/PageLoader";

import AppRouter from "./appRouter"; // Router

import { setProfile } from "./features/ProfileSlice";
import { SearchCust, CustProfile } from "services/Api/Module/Customer";

const devMode = false;

function App() {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const [isHasLineProfile, setIsHasLineProfile] = useState(false);
  const [isHasProfile, setIsHasProfile] = useState(false);

  let { isLoggedIn, liff } = useLiff();

  if (!isLoggedIn) {
    isLoggedIn = devMode; // mock true เอาไว้ dev
  }

  const initialized = useRef(false);

  useEffect(() => {
    // console.log("isLoggedIn >>", isLoggedIn);

    if (!isLoggedIn && devMode) return;

    (async () => {
      try {
        const profile = await liff.getProfile();

        if (profile) {
          if (!initialized.current) {
            initialized.current = true;

            const sub = liff.getDecodedIDToken().sub;
            // console.log(profile, sub);

            let tmpObj = {
              lineUid: sub,
            };
            // console.log(tmpObj);

            getSearchCust({
              q: "openApp",
              lineUid: sub,
            }).then((_rsData) => {
              // console.log("_rsData >>", _rsData, (_rsData.result?.id !== undefined));

              if (typeof _rsData.result.token !== "undefined") {
                window.localStorage.setItem("token", _rsData.result.token);
              }

              if (_rsData.status === true) {
                if (_rsData.result?.id !== undefined) {
                  tmpObj = {
                    ...tmpObj,
                    id: _rsData.result.id,
                  };

                  getCustProfile({
                    id: _rsData.result.id,
                    lineUid: sub,
                  }).then((_rsDataCustProfile) => {
                    // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

                    if (
                      _rsDataCustProfile.status === true &&
                      _rsDataCustProfile.result.length !== 0
                    ) {
                      tmpObj = {
                        ...tmpObj,
                        dataUser: _rsDataCustProfile.result,
                        custNo: _rsDataCustProfile.result.custNo,
                      };

                      setIsHasProfile(true);
                    } else {
                      console.warn(_rsDataCustProfile);
                    }

                    // console.log(tmpObj);
                    dispatch(setProfile(tmpObj));

                    setIsHasLineProfile(true);
                  });
                } else {
                  dispatch(setProfile(tmpObj));

                  setIsHasLineProfile(true);
                }
              }
            });
          }
        }
      } catch (error) {
        // console.warn(error);
      }
    })();
  }, [liff, isLoggedIn, dispatch, stateProfile]);

  const getSearchCust = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await SearchCust({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSearchCust resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getSearchCust >> ", e);
    }

    return _return;
  };

  const getCustProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CustProfile({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustProfile resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustProfile >> ", e);
    }

    return _return;
  };

  if (!devMode && !isHasLineProfile) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  return (
    <>
      {(isLoggedIn || devMode) && (
        <MainLayout>
          <AppRouter isHasProfile={isHasProfile} profileData={stateProfile} />
        </MainLayout>
      )}
    </>
  );
}

export default App;
