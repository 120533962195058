import React, {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import WelcomePage from "pages/welcome";
import HomePage from "pages/home";

import LoginPage from "pages/account/login";

import AuthUserPage from "pages/account/auth";
import AuthConfirmPage from "pages/account/auth/confirm";
import RegisterUserPage from "pages/account/register";
import AuthOtpPage from "pages/account/auth/otp";

import ProfilePage from "pages/account/profile";

import WarrantyPage from "pages/account/warranty";
import WarrantyDetailPage from "pages/account/warranty/detail";

import CouponPage from "pages/account/coupon";
import CouponDetailPage from "pages/account/coupon/detail";
import CouponBarcodePage from "pages/account/coupon/barcode";
import CouponHistoryPage from "pages/account/coupon/history";
import CouponHistoryDetailPage from "pages/account/coupon/historyDetail";

import PrivacyPolicyPage from "pages/consent/privacyPolicy";

const appRouter = (props) => {
  // console.log(props.isHasProfile, props.profileData);

  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/" element={<HomePage />} /> */}
          <Route
            path="/"
            element={
              props.isHasProfile === true ? (
                <Navigate to="/profile" />
              ) : (
                <HomePage />
              )
            }
          />

          {/* <Route path="/home" element={<HomePage />} /> */}
          <Route
            path="/home"
            element={
              props.isHasProfile === true ? (
                <Navigate to="/profile" />
              ) : (
                <HomePage />
              )
            }
          />

          {/* <Route path="/login" element={<LoginPage />} /> */}

          <Route path="/auth-user" element={<AuthUserPage />} />
          <Route path="/auth-confirm" element={<AuthConfirmPage />} />
          <Route path="/register" element={<RegisterUserPage />} />
          <Route path="/auth-otp" element={<AuthOtpPage />} />

          <Route path="/welcome" element={<WelcomePage />} />

          {/* <Route path="/profile" element={<ProfilePage />} /> */}
          <Route
            path="/profile"
            element={
              props.isHasProfile === true ||
              props.profileData.custNo !== null ? (
                <ProfilePage />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route path="/warranty" element={<WarrantyPage />} />
          <Route path="/warranty/detail" element={<WarrantyDetailPage />} />

          <Route path="/coupon" element={<CouponPage />} />
          <Route path="/coupon/detail" element={<CouponDetailPage />} />
          <Route path="/coupon/barcode" element={<CouponBarcodePage />} />
          <Route path="/coupon/history" element={<CouponHistoryPage />} />
          <Route
            path="/coupon/history/detail"
            element={<CouponHistoryDetailPage />}
          />

          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

          {/* Not found 404 */}
          <Route
            path="*"
            exact={true}
            // element={<Navigate replace to="/404" />}
            element={<Navigate replace to="/" />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default appRouter;
