import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { CouponHistory } from "services/Api/Module/Customer";

import PageHeader from "layouts/inc/PageHeader";
import PageTitle from "layouts/inc/PageTitle";

import MenuMember from "components/menu/MenuMember";
import CardCouponHistory from "components/card/CardCouponHistory";
import TextLoader from "components/loader/TextLoader";

import icoCouponGray from "../../../assets/img/ci/coupon/ico-coupon-gray.svg";

import useTranslations from "../../../i18n/useTranslations";

const MyCouponHistory = ({ articleItem }) => {
  // console.log(articleItem);

  const transitions = useTransition(articleItem, {
    from: { opacity: 0, transform: "translateY(30px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    leave: { opacity: 0, transform: "translateY(30px)" },
  });

  return (
    // <div className="item-group" style={{ position: "relative" }}>
    transitions((style, item) => (
      <animated.div
        className="item-coupon-history"
        style={{
          ...style,
          willChange: "transform, opacity",
        }}
      >
        {item}
      </animated.div>
    ))
    // </div>
  );
};

const CouponHistoryList = () => {
  const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("handleGotoPage >>", e.target.dataset.page);

    const _page = e.target.dataset.page;
    navigate(_page);
  };
  /* End Handle goto page */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);
  const [articleItem, setArticleItem] = useState([]);

  useEffect(() => {
    if (stateProfile.lineUid === null || stateProfile.custNo === null) {
      navigate("/");
    }

    getCouponHistory({
      custId: stateProfile.id,
      custType: stateProfile.dataUser.custType,
    }).then((_rsDataCouponHistory) => {
      // console.log("_rsDataCouponHistory >>", _rsDataCouponHistory);

      if (
        _rsDataCouponHistory.status === true &&
        _rsDataCouponHistory.result.length !== 0
      ) {
        const _length = _rsDataCouponHistory.result.length;
        const _coupon = _rsDataCouponHistory.result;
        // console.log(_coupon);

        for (let i = 0; i < _length; i++) {
          setArticleItem((prevArticleItem) => [
            ...prevArticleItem,
            <CardCouponHistory
              key={i + 1}
              toDate={_coupon[i].to_date}
              name={t.lang === "en" ? _coupon[i].name_en : _coupon[i].name_th}
              minTotal={_coupon[i].min_total}
              discount={_coupon[i].discount}
              thumbnail={_coupon[i].raw_image}
              point={_coupon[i].point}
              dataCoupon={_coupon[i]}
            />,
          ]);
        }
      }

      setIsLoading(false);
    });
  }, []);
  /* End First event render */

  const getCouponHistory = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CouponHistory({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCouponHistory resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCouponHistory >> ", e);
    }

    return _return;
  };

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageCoupon-History">
        <PageHeader />

        <main>
          <div className="wrapContent">
            <PageTitle titleText={t.pageTitle.coupon} />

            <section id="pSubmenuMain">
              <div className="bSubmenu">
                <a
                  className=""
                  href="#"
                  data-page="/coupon"
                  onClick={handleGotoPage}
                >
                  {t.couponHistoryPage.tabCoupon}
                </a>
                <a
                  className="active"
                  href="#"
                  data-page="/coupon/history"
                  onClick={handleGotoPage}
                >
                  {t.couponHistoryPage.tabCouponHistory}
                </a>
              </div>
            </section>

            <section id="pListCouponMain">
              <div className="bListCoupon">
                {articleItem.length === 0 ? (
                  <div className="bNoData">
                    {isLoading ? (
                      <TextLoader />
                    ) : (
                      <>
                        <img src={icoCouponGray} alt="" />
                        <p>{t.couponHistoryPage.cardNotFound}</p>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    {/* <div className="bGroup use">{articleItem}</div> */}
                    <div className="bGroup use">
                      <MyCouponHistory articleItem={articleItem} />
                    </div>
                    <div className="bNoData hide">
                      <img src={icoCouponGray} alt="" />
                      <p>{t.couponHistoryPage.cardNotFound}</p>
                    </div>
                  </>
                )}
              </div>
            </section>

            <MenuMember pageActive={"coupon"} />
          </div>
        </main>
      </div>
    </>
  );
};

export default CouponHistoryList;
