import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";
import { setProfile } from "../../../features/ProfileSlice";
import {
  OtpRequest,
  OtpVerify,
  UpdateCust,
  CustProfile,
  RegisterCust,
} from "services/Api/Module/Customer";

import { convertMobileNoFormat } from "services/helper";

import PageHeader from "layouts/inc/PageHeader";
import PageTitle from "layouts/inc/PageTitle";

import ModalContext from "contexts/ModalContext";

import useTranslations from "../../../i18n/useTranslations";

const Otp = () => {
  const { modalAlert } = useContext(ModalContext); // import modal

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);
  // console.log(stateProfile);

  const { t } = useTranslations(); // แปลภาษา

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("auth-otp");

  const PageRoute = {
    register: "/register",
    "auth-user": "/auth-user",
    welcome: "/welcome",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = () => {
    setPrevPage(page);

    if (stateRegister.prevPage === "auth-confirm") {
      navigate(PageRoute["auth-user"]);
    } else {
      navigate(PageRoute["register"]);
    }
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Handle OTP input */
  const [otp, setOtp] = useState("");
  // const [refOtp, setRefOtp] = useState("");

  async function handlePasteOtp(e) {
    // console.log("handlePasteOtp e >>", e.clipboardData.getData("text"));
    const textClipboard = e.clipboardData.getData("text");
    let maxLength = 6;

    if (textClipboard) {
      const smsCodeElements = document.querySelectorAll(".smsCode");

      for (let i = 0; i < maxLength; i++) {
        if (textClipboard[i]) {
          smsCodeElements[i].value = textClipboard[i];
        }
      }
      if (textClipboard.length) {
        if (textClipboard.length < maxLength) {
          smsCodeElements[textClipboard.length - 1].focus();
        } else {
          smsCodeElements[maxLength - 1].focus();
        }
      }
    }
  }
  async function handleInputOtp(e) {
    // console.log("handleInputOtp!!", e);

    const target = e.target;
    const val = target.value;

    if (isNaN(val)) {
      target.value = "";
      return;
    }

    if (val != "") {
      const next = target.nextElementSibling;
      if (next) {
        next.focus();
      }
    }

    setValueOtp();
  }
  function handleKeyupOtp(e) {
    const target = e.target;
    const key = e.key.toLowerCase();

    if (key == "backspace" || key == "delete") {
      target.value = "";
      const prev = target.previousElementSibling;
      if (prev) {
        prev.focus();
      }
      return;
    }
  }

  function setValueOtp() {
    let otpValue = "";
    const smsCodeElements = document.querySelectorAll(".smsCode");
    for (const smsCodeElement of smsCodeElements) {
      // console.log("smsCodeElement.value >>", smsCodeElement.value);
      otpValue += smsCodeElement.value;
    }
    setOtp(otpValue);
  }
  /* End Handle OTP input */

  /* Handle click submit form */
  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(stateRegister);
    // console.log(otp);

    document
      .getElementsByClassName("btn-submit-otp")[0]
      .setAttribute("disabled", "");

    const payloadOtpVerify = {
      token: otpToken,
      pin: otp,
    };

    postOtpVerify(payloadOtpVerify).then((_rsDataOtpVerify) => {
      // console.log(_rsDataOtpVerify);

      if (
        _rsDataOtpVerify.result !== null &&
        _rsDataOtpVerify.result.status === "success"
      ) {
        let payload = {
          ...stateRegister.dataUser,
          isVerified: true,
          lineUid: stateProfile.lineUid,
        };

        /*** Verify customer ***/
        if (stateRegister.prevPage === "auth-confirm") {
          switch (stateRegister.dataFrom) {
            case "HUG_exist":
              payload = {
                ...payload,
                dataFrom: stateRegister.dataFrom,
              };

              postRegisterCust(payload).then((_rsDataRegisterCust) => {
                // console.log("_rsDataRegisterCust >>", _rsDataRegisterCust);

                if (
                  _rsDataRegisterCust.status === true &&
                  _rsDataRegisterCust.result.length !== 0
                ) {
                  if (payload.lineUid !== null) {
                    getCustProfile({
                      lineUid: payload.lineUid,
                    }).then((_rsDataCustProfile) => {
                      // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

                      document
                        .getElementsByClassName("btn-submit-otp")[0]
                        .removeAttribute("disabled");

                      if (
                        _rsDataCustProfile.status === true &&
                        _rsDataCustProfile.result.length !== 0
                      ) {
                        setDataProfile(_rsDataCustProfile.result);
                      } else {
                        console.warn(_rsDataCustProfile);
                      }
                    });
                  }
                } else {
                  console.warn(_rsDataRegisterCust);
                }
              });
              break;

            case "HUG_update":
              delete payload.branchCode;
              delete payload.currentPoint;
              delete payload.custNo;
              delete payload.custStat;
              delete payload.custType;
              delete payload.nationCode;
              delete payload.totalBuy;

              postUpdateCust(payload).then((_rsDataUpdateCust) => {
                // console.log(_rsDataUpdateCust);

                if (_rsDataUpdateCust.status === true) {
                  if (payload.lineUid !== null) {
                    getCustProfile({
                      lineUid: payload.lineUid,
                    }).then((_rsDataCustProfile) => {
                      // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

                      document
                        .getElementsByClassName("btn-submit-otp")[0]
                        .removeAttribute("disabled");

                      if (
                        _rsDataCustProfile.status === true &&
                        _rsDataCustProfile.result.length !== 0
                      ) {
                        setDataProfile(_rsDataCustProfile.result);
                      } else {
                        console.warn(_rsDataCustProfile);
                      }
                    });
                  }
                } else {
                  console.warn(_rsDataUpdateCust);
                }
              });
              break;

            default:
              postUpdateCust(payload).then((_rsDataUpdateCust) => {
                // console.log(_rsDataUpdateCust);

                if (_rsDataUpdateCust.status === true) {
                  if (payload.lineUid !== null) {
                    getCustProfile({
                      lineUid: payload.lineUid,
                    }).then((_rsDataCustProfile) => {
                      // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

                      document
                        .getElementsByClassName("btn-submit-otp")[0]
                        .removeAttribute("disabled");

                      if (
                        _rsDataCustProfile.status === true &&
                        _rsDataCustProfile.result.length !== 0
                      ) {
                        setDataProfile(_rsDataCustProfile.result);
                      } else {
                        console.warn(_rsDataCustProfile);
                      }
                    });
                  }
                } else {
                  console.warn(_rsDataUpdateCust);
                }
              });
              break;
          }
        } else {
          /*** Register customer ***/
          const newData = {
            custType: "G",
            nationCode: "1",
            branchCode: "30",
            currentPoint: 0,
            totalBuy: 0,
          };

          payload = {
            ...payload,
            dataFrom: stateRegister.dataFrom,
            ...newData,
          };

          postRegisterCust(payload).then((_rsDataRegisterCust) => {
            // console.log("_rsDataRegisterCust >>", _rsDataRegisterCust);

            document
              .getElementsByClassName("btn-submit-otp")[0]
              .removeAttribute("disabled");

            if (
              _rsDataRegisterCust.status === true &&
              _rsDataRegisterCust.result.length !== 0
            ) {
              setDataProfile(_rsDataRegisterCust.result);
            } else {
              console.warn(_rsDataRegisterCust);
            }
          });
        }
      } else {
        console.warn(_rsDataOtpVerify);
      }
    });
  };

  const setDataProfile = (newData = {}) => {
    const tmpObj = {
      id: newData.id,
      custNo: newData.custNo,
      dataUser: newData,
    };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const postOtpVerify = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await OtpVerify({ data: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postOtpVerify resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "",
          _subTitle = "";

        if (data.resultCode === 40400) {
          switch (data.resultError.code) {
            case "otp_invalid":
              _title = `${t.otpPage.modalAlertMsg.otp_invalid.title}`;
              break;

            case "otp_expired":
              _title = `${t.otpPage.modalAlertMsg.otp_expired.title}`;
              break;

            default:
              _title = `${t.otpPage.modalAlertMsg.default.title}`;
              _subTitle = `${t.otpPage.modalAlertMsg.default.subTitle}`;

              modalAlert.info({
                open: true,
                title: _title,
                subTitle: _subTitle,
                content: "",
              });
              break;
          }

          if (
            data.resultError.code === "otp_invalid" ||
            data.resultError.code === "otp_expired"
          ) {
            const _form = document.querySelectorAll(
              "#pRegisterMain .bOtp .bFrom"
            );
            _form[0].classList.add("error");

            const _txtError = document.querySelectorAll(
              "#pRegisterMain .bOtp .bFrom .tError"
            );
            _txtError[0].textContent = _title;
          }
        }

        document
          .getElementsByClassName("btn-submit-otp")[0]
          .removeAttribute("disabled");
      }
    } catch (e) {
      console.log("ERR postOtpVerify >> ", e);
    }

    return _return;
  };

  const postUpdateCust = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await UpdateCust({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postUpdateCust resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `${t.otpPage.modalAlertMsg.default.title}`,
          _subTitle = `${t.otpPage.modalAlertMsg.default.subTitle}`;

        if (data.resultCode === 40400) {
          _title = `${t.otpPage.modalAlertMsg.code40400.title}`;
        }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR postUpdateCust >> ", e);
    }

    return _return;
  };

  const getCustProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CustProfile({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustProfile resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustProfile >> ", e);
    }

    return _return;
  };

  const postRegisterCust = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await RegisterCust({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20201) {
        const resData = data.body;
        // console.log("postRegisterCust resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `${t.otpPage.modalAlertMsg.default.title}`,
          _subTitle = `${t.otpPage.modalAlertMsg.default.subTitle}`;

        // if (data.resultCode === 40400) {
        //   _title = "";
        //   _subTitle = "";
        // }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR postRegisterCust >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* First event render */
  const postOtpRequest = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await OtpRequest({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postOtpRequest resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `${t.otpPage.modalAlertMsg.default.title}`,
          _subTitle = `${t.otpPage.modalAlertMsg.default.subTitle}`;

        // if (data.resultCode === 40400) {
        //   _title = "";
        //   _subTitle = "";
        // }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR postOtpRequest >> ", e);
    }

    return _return;
  };

  const [otpToken, setOtpToken] = useState("");
  const [otpRefno, setOtpRefno] = useState("");

  const handleOtpRequest = (mobileNo) => {
    const payloadOtpRequest = {
      msisdn: mobileNo,
    };

    postOtpRequest(payloadOtpRequest).then((_rsDataOtpRequest) => {
      // console.log(_rsDataOtpRequest);

      if (
        _rsDataOtpRequest.result !== null &&
        Object.keys(_rsDataOtpRequest.result).length !== 0
      ) {
        if (
          "status" in _rsDataOtpRequest.result &&
          _rsDataOtpRequest.result.status === "success"
        ) {
          setOtpToken(_rsDataOtpRequest.result.token);
          setOtpRefno(_rsDataOtpRequest.result.refno);
        } else {
          console.warn(_rsDataOtpRequest);
        }
      } else {
        console.warn(_rsDataOtpRequest);
      }
    });
  };

  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    if (stateProfile.lineUid !== null && stateProfile.custNo !== null) {
      navigate("/profile");
    }

    const { dataUser } = stateRegister;
    if (Object.keys(dataUser).length !== 0) {
      if (dataUser.mobileNo) {
        handleOtpRequest(dataUser.mobileNo);
      }
    } else {
      navigate("/");
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log(stateProfile);

    if (stateProfile.dataUser.id && stateProfile.dataUser.id !== null) {
      goNext("welcome");
    }
  }, [stateProfile]);

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageRegisterOTP">
        <PageHeader />
        <main>
          <div className="wrapContent">
            <PageTitle />
            <section id="pTitleContentMain">
              <div className="bTitleContent">
                <div className="tTag">{t.otpPage.headTitle}</div>
                <div className="bLogo">
                  <img src="assets/img/logo/logo-color.svg" alt="Logo-G99" />
                </div>
                <h2>{t.otpPage.headSubTitle}</h2>
                <div className="tStep">
                  <span>3/3</span>
                </div>
              </div>
            </section>
            <form onSubmit={handleSubmit}>
              <section id="pRegisterMain">
                <div className="bRegister">
                  <h3>
                    <img src="assets/img/ci/ico-otp-round.svg" alt="" />
                    {t.otpPage.formTitle}
                  </h3>
                  <div className="bOtp">
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          t.otpPage.formSubTitle +
                          " " +
                          (stateRegister.dataUser.mobileNo
                            ? convertMobileNoFormat(
                                stateRegister.dataUser.mobileNo,
                                "-"
                              )
                            : ""),
                      }}
                    ></p>
                    <div className="bFrom">
                      <div className="control-group otp">
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onPaste={handlePasteOtp}
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode"
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                      </div>
                      <div className="bTextSend">
                        <p>
                          {t.otpPage.formFooter.str1 + " "}
                          <a
                            onClick={() =>
                              stateRegister.dataUser.mobileNo
                                ? handleOtpRequest(
                                    stateRegister.dataUser.mobileNo
                                  )
                                : console.warn(stateRegister.dataUser.mobileNo)
                            }
                          >
                            {t.otpPage.formFooter.str2}
                          </a>
                        </p>
                      </div>
                      <p className="tError"></p>
                    </div>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button className="btn outline" onClick={() => goBack()}>
                    {t.btn.back}
                  </button>
                  <button
                    className="btn btn-submit-otp"
                    disabled={otp.length < 6}
                  >
                    {t.btn.next}
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default Otp;
