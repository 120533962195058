import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCoupon } from "../../features/CouponSlice";

import icoCouponGray from "../../assets/img/ci/coupon/ico-coupon-gray.svg";

import Helper, { convertDateFormat } from "services/helper";

import useTranslations from "../../i18n/useTranslations";

const CardCouponHistory = (props) => {
  const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateCoupon = useSelector((state) => state.coupon);

  const setDataCoupon = (newData) => {
    const tmpObj = { id: newData.id, data: newData };
    dispatch(setCoupon(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto coupon detail page */
  const navigate = useNavigate();

  const handleGotoCouponHistoryDetail = (e) => {
    e.preventDefault();
    // console.log("handleGotoCouponHistoryDetail >>", props.dataCoupon);

    setDataCoupon(props.dataCoupon);

    navigate("/coupon/history/detail");
  };
  /* End Handle goto coupon detail page */

  useEffect(() => {
    // if (typeof stateCoupon === "object") {
    //   // console.log(stateCoupon);
    // }
  }, [stateCoupon]);

  return (
    <article
      data-to-date={convertDateFormat(props.toDate)}
      data-discount={props.discount}
      data-name={props.name.toLowerCase()}
    >
      <div className="bPic">
        <img
          src={props.thumbnail !== null ? props.thumbnail : icoCouponGray}
          alt=""
        />
      </div>
      <div className="divider"></div>
      <div className="bDetails">
        <div className="bValue">
          <div className="bDate">
            {t.couponHistoryPage.cardValidTill}{" "}
            {convertDateFormat(props.toDate, "d/m/y")}
          </div>
          <span>{props.name}</span>
          {t.couponHistoryPage.cardForSpend.str1}{" "}
          {Helper.FN.toNumberWithCommas(props.minTotal)}{" "}
          {t.couponHistoryPage.cardForSpend.str2}
        </div>
        <div className="bPoint">
          <span>
            {Helper.FN.toNumberWithCommas(props.point)}{" "}
            {t.couponHistoryPage.cardPoint}
          </span>
          <div className="bBtn">
            <a href="#" onClick={handleGotoCouponHistoryDetail}>
              {t.couponHistoryPage.cardUsed}
            </a>
          </div>
        </div>
      </div>
    </article>
  );
};

export default CardCouponHistory;
