import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../features/ProfileSlice";

import PageHeader from "layouts/inc/PageHeader";

import Helper, { convertDateFormat } from "services/helper";

import useTranslations from "../../../i18n/useTranslations";

// import moment from "moment";
import Barcode from "react-barcode";

const CouponBarcode = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateCoupon = useSelector((state) => state.coupon);

  const setDataProfile = () => {
    const tmpObj = { usePoint: true };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const { t } = useTranslations(); // แปลภาษา

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/coupon");
  };
  /* End Handle goback */

  /* First event render */
  const [couponCode, setCouponCode] = useState(null);

  useEffect(() => {
    // console.log(
    //   "useEffect !",
    //   stateProfile,
    //   stateCoupon
    //   // moment().format("YYYY/MM/DD")
    // );

    if (stateProfile.lineUid === null || stateProfile.custNo === null) {
      navigate("/");
    }

    if (stateCoupon.id === 0 || stateCoupon.data.length === 9) {
      navigate("/coupon");
    } else {
      setCouponCode(stateCoupon.data.couponCode);
    }
  }, []);
  /* End First event render */

  /* Run countdonw timer */
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const toggleTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  useEffect(() => {
    let timerInterval;

    if (runTimer) {
      setCountDown(60 * stateCoupon.data.qr_expired);
      // setCountDown(60 * 0.1);

      timerInterval = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("timeout >> expired");

      setCountDown(0);
      setRunTimer(false);
      setIsExpired(true);
    }
  }, [countDown, runTimer, isExpired]);
  /* End Run countdonw timer */

  useEffect(() => {
    // console.log(
    //   "useEffect >>> couponUsed, runNumber, couponCode",
    //   couponUsed,
    //   runNumber,
    //   couponCode
    // );

    if (couponCode !== null) {
      toggleTimer();

      setDataProfile();
    }
  }, [couponCode]);

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageCoupon-Scan">
        <PageHeader />

        <main>
          <div className="wrapContent">
            <section id="pCouponDetails">
              <div className="bCouponDetails">
                <div className="bTitle">
                  <h1>
                    {t.lang === "en"
                      ? stateCoupon.data.name_en
                      : stateCoupon.data.name_th}{" "}
                    {t.couponBarcodePage.detailForOrderAmount}{" "}
                    {Helper.FN.toNumberWithCommas(stateCoupon.data.min_total)}.-
                  </h1>
                  <p>
                  {t.couponBarcodePage.detailValidTill}{" "}
                    {convertDateFormat(stateCoupon.data.to_date, "d/m/y")}
                  </p>
                </div>
                <div className="bPic">
                  <h2>{t.couponBarcodePage.scanBarcode}</h2>
                  {couponCode !== null ? (
                    <div
                      style={{
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      <Barcode displayValue={false} value={couponCode} />
                      <h3>{t.couponBarcodePage.couponCode} : {couponCode}</h3>
                      <p>{t.couponBarcodePage.remarkCouponCode}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {isExpired === true ? (
                  <div className="bTimeout">
                    <p>{t.couponBarcodePage.barcodeExpired}</p>
                  </div>
                ) : (
                  <div className="bTime">
                    <div>{t.couponBarcodePage.barcodeExpireIn}</div>
                    <div>
                      <span>{minutes}</span> {t.couponBarcodePage.minute}
                    </div>
                    <div>
                      <span>{seconds}</span> {t.couponBarcodePage.second}
                    </div>
                  </div>
                )}
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <a className="btn outline" onClick={handleGoBack}>
                  {t.btn.back}
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default CouponBarcode;
