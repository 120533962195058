import { useDispatch, useSelector } from "react-redux";

import logo from "assets/img/logo/logo.svg";

import useTranslations from "../../i18n/useTranslations";

function PageHeader() {
  const profile = useSelector((state) => state.profile); // ไว้ดึง state profile

  const { lang, supportedLangs, setLang } = useTranslations(); // สำหรับเปลี่ยนภาษา
  const { t } = useTranslations();

  return (
    <>
      <header>
        {/* <div style={{fontSize: 8}}>{JSON.stringify(profile)}</div> */}
        <div className="bHeader">
          <div className="bLogo">
            <img src={logo} alt="Logo-G99" />
          </div>
          <div className="bLang">
            <div className="activeLang" data-lang={lang}>
              {lang}
            </div>
            <div className="bListlang">
              <a className="lang" data-lang="th" id="btnTH" onClick={() => setLang("th")}>
                th
              </a>
              <a className="lang" data-lang="en" id="btnEN" onClick={() => setLang("en")}>
                en
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default PageHeader;
