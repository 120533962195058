import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";
import { PatternFormat } from "react-number-format";
import { SearchCust, CheckAccountExists } from "services/Api/Module/Customer";

import PageHeader from "layouts/inc/PageHeader";
import PageTitle from "layouts/inc/PageTitle";

import ModalContext from "contexts/ModalContext";

import Helper, {
  convertDateFormat,
  convertMobileNoFormat,
  breakKeyDownEnter,
} from "services/helper";

import useTranslations from "../../../i18n/useTranslations";

const AuthUser = () => {
  const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  const { modalAlert } = useContext(ModalContext); // import modal

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("auth-user");

  const PageRoute = {
    home: "/",
    "auth-confirm": "/auth-confirm",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    setValue,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      idCard: "",
      birthDate: "",
      mobileNo: "",
    },
  });

  // console.log("isValid: ", isValid);
  /* End Form */

  /* onChange Radio input search type */
  let searchTypeSelected = "phonenumber";
  const [searchType, setSearchType] = useState(searchTypeSelected);

  const changeSearchType = (e) => {
    searchTypeSelected = e.target.value;

    switch (searchType) {
      case "phonenumber":
        resetField("mobileNo");
        break;

      default:
        resetField("birthDate");
        break;
    }

    document.getElementsByClassName("input-" + searchType)[0].style.display =
      "none";

    setSearchType(searchTypeSelected);

    document.getElementsByClassName(
      "input-" + searchTypeSelected
    )[0].style.display = "block";
  };

  useEffect(() => {
    // console.log(searchType);
  }, [searchType]);
  /* End onChange Radio input search type */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log('onSubmit >>', data);

    if (data) {
      let payload = {
        q: "verify",
        idCard: data.idCard,
      };

      if (typeof data.birthDate !== "undefined" && data.birthDate !== "") {
        payload = {
          ...payload,
          birthDate: data.birthDate,
        };
      }

      if (typeof data.mobileNo !== "undefined" && data.mobileNo !== "") {
        payload = {
          ...payload,
          mobileNo: convertMobileNoFormat(data.mobileNo),
        };
      }

      // console.log(payload);
      getSearchCust(payload).then((_rsData) => {
        // console.log("getSearchCust >>>", _rsData);

        if (_rsData.status === true && _rsData.result.length !== 0) {
          const _dataFrom = _rsData.result.dataFrom
            ? _rsData.result.dataFrom
            : null;

          let _dataUser = {
            fullname: _rsData.result.fullname,
            idCard: _rsData.result.idCard,
            birthDate: convertDateFormat(_rsData.result.birthDate),
            mobileNo: _rsData.result.mobileNo,
            isConsent: null,
          };

          if (_dataFrom !== null) {
            _dataUser = {
              ..._dataUser,
              branchCode: _rsData.result.branchCode,
              currentPoint: _rsData.result.currentPoint
                ? _rsData.result.currentPoint
                : 0,
              custNo: _rsData.result.custNo,
              custStat: _rsData.result.custStat,
              custType: _rsData.result.custType,
              nationCode: _rsData.result.nationCode,
              totalBuy: _rsData.result.totalBuy ? _rsData.result.totalBuy : 0,
            };
          }

          setDataRegis(_dataUser, _dataFrom);

          goNext("auth-confirm");
        } else {
          console.warn(_rsData);
        }
      });
    }
  };

  const setDataRegis = (newData = {}, dataFrom = null) => {
    const tmpObj = { dataUser: newData, dataFrom: dataFrom };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const getSearchCust = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await SearchCust({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSearchCust resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "",
          _subTitle = "",
          _content = `${t.authUserPage.modalAlertMsg.default.content}`;

        if (data.resultCode === 40401) {
          switch (data.resultError.code) {
            case "idCard_notfound":
              _title = `${t.authUserPage.modalAlertMsg.idCard_notfound.title}`;
              _subTitle = `${t.authUserPage.modalAlertMsg.idCard_notfound.subTitle}`;
              break;

            case "birthDate_notfound":
              _title = `${t.authUserPage.modalAlertMsg.birthDate_notfound.title}`;
              _subTitle = `${t.authUserPage.modalAlertMsg.birthDate_notfound.subTitle}`;
              break;

            case "mobileNo_notfound":
              _title = `${t.authUserPage.modalAlertMsg.mobileNo_notfound.title}`;
              _subTitle = `${t.authUserPage.modalAlertMsg.mobileNo_notfound.subTitle}`;
              break;

            default:
              _title = `${t.authUserPage.modalAlertMsg.default.title}`;
              _subTitle = `${t.authUserPage.modalAlertMsg.default.subTitle}`;
              break;
          }
        }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: _content,
        });
      }
    } catch (e) {
      console.log("ERR getSearchCust >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* First event render */
  useEffect(() => {
    if (stateProfile.lineUid !== null && stateProfile.custNo !== null) {
      navigate("/profile");
    }

    const { dataUser } = stateRegister;

    // console.log("useEffect !", stateRegister);

    if (Object.keys(dataUser).length !== 0) {
      setValue("idCard", dataUser.idCard);
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageRegisterVerify">
        <PageHeader />
        <main>
          <div className="wrapContent">
            <PageTitle />

            <section id="pTitleContentMain">
              <div className="bTitleContent">
                <div className="tTag">{t.authUserPage.headTitle}</div>
                <div className="bLogo">
                  <img src="assets/img/logo/logo-color.svg" alt="Logo-G99" />
                </div>
                <h2>{t.authUserPage.headSubTitle}</h2>
                <div className="tStep">
                  <span>2/3</span>
                </div>
              </div>
            </section>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pRegisterMain">
                <div className="bRegister">
                  <h3>
                    <img src="assets/img/ci/ico-search-round.svg" alt="" />
                    {t.authUserPage.formTitle}
                  </h3>
                  <div className="bForm">
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.idCard ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">{t.formField.idCard.label}</div>
                        <Controller
                          name="idCard"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `${t.formField.idCard.validate.required}`,
                            pattern: {
                              value: /^\d{13}$/,
                              message: `${t.formField.idCard.validate.pattern}`,
                            },
                          }}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              maxLength={13}
                              placeholder={t.formField.idCard.placeHolder}
                              onInput={Helper.FNFORM.handleNumberOnly}
                            />
                          )}
                        />
                        {errors.idCard && (
                          <label htmlFor="" className="tError">
                            {errors.idCard.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group searchtype">
                        <label className="control control--radio">
                          {t.authUserPage.formField.radioBtn.mobileNo}
                          <input
                            type="radio"
                            name="searchtype"
                            value="phonenumber"
                            checked={searchType === "phonenumber"}
                            onChange={(e) => changeSearchType(e)}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        <label className="control control--radio">
                          {t.authUserPage.formField.radioBtn.birthDate}
                          <input
                            type="radio"
                            name="searchtype"
                            value="birthday"
                            checked={searchType === "birthday"}
                            onChange={(e) => changeSearchType(e)}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        {/* <label className="tError">
                          กรุณาเลือกประเภทการค้นหา
                        </label> */}
                      </div>
                    </div>
                    <div className="bRow phonenumber input-phonenumber">
                      <div
                        className={`control-group ${
                          errors.mobileNo ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">
                          {t.formField.mobileNo.label}
                        </div>
                        <Controller
                          name="mobileNo"
                          control={control}
                          defaultValue=""
                          rules={{
                            required:
                              searchType === "phonenumber"
                                ? `${t.formField.mobileNo.validate.required}`
                                : false,
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                              message: `${t.formField.mobileNo.validate.pattern}`,
                            },
                          }}
                          disabled={searchType !== "phonenumber" ?? true}
                          render={({ field: { name, value, onChange } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              onChange={onChange}
                              displayType="input"
                              format="###-###-####"
                              placeholder={t.formField.mobileNo.placeHolder}
                            />
                          )}
                        />
                        {errors.mobileNo && (
                          <label htmlFor="" className="tError">
                            {errors.mobileNo.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow birthday input-birthday">
                      <div
                        className={`control-group ${
                          errors.birthDate ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">
                          {t.formField.birthDate.label}
                        </div>
                        <Controller
                          name="birthDate"
                          control={control}
                          defaultValue=""
                          rules={{
                            required:
                              searchType === "birthday"
                                ? `${t.formField.birthDate.validate.required}`
                                : false,
                          }}
                          disabled={searchType !== "birthday" ?? true}
                          render={({ field }) => (
                            <input {...field} type="date" />
                          )}
                        />
                        {errors.birthDate && (
                          <label htmlFor="" className="tError">
                            {errors.birthDate.message}
                          </label>
                        )}
                      </div>
                    </div>
                    {/* <p className="tError">Text Error Main Form</p> */}
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn outline"
                    onClick={(e) => {
                      e.preventDefault();
                      goBack("home");
                    }}
                  >
                    {t.btn.back}
                  </button>
                  <button
                    className="btn"
                    type="submit"
                    disabled={isValid ? false : true}
                  >
                    {t.btn.next}
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default AuthUser;
