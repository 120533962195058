import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCoupon, resetCoupon } from "../../../features/CouponSlice";

import { CouponHistory, CreateCoupon } from "services/Api/Module/Customer";

import $ from "jquery";
import moment from "moment";

import icoCoupon from "../../../assets/img/ci/coupon/ico-coupon.svg";

import PageHeader from "layouts/inc/PageHeader";

import ModalContext from "contexts/ModalContext";
import ModalCouponAlert from "components/modal/ModalCouponAlert";

import Helper, { convertDateFormat } from "services/helper";

import useTranslations from "../../../i18n/useTranslations";

const CouponDetail = () => {
  const { modalAlert } = useContext(ModalContext); // import modal

  const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateCoupon = useSelector((state) => state.coupon);

  const setDataCoupon = (newData) => {
    const tmpObj = { data: newData };
    dispatch(setCoupon(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataCoupon = () => {
    dispatch(resetCoupon()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    resetDataCoupon();

    navigate(-1);
  };
  /* End Handle goback */

  /* Handle change tabs */
  const handleChangeTab = (e) => {
    e.preventDefault();
    // console.log("handleChangeTab >>", e.target.id);

    if (e.target.id === "btnTab1") {
      $("#pageCoupon-Details").find(".bBtnTab a").removeClass("active");
      $("#pageCoupon-Details")
        .find("#" + e.target.id)
        .addClass("active");
      $("#pageCoupon-Details").find("#tab1").show();
      $("#pageCoupon-Details").find("#tab2").hide();
    }

    if (e.target.id === "btnTab2") {
      $("#pageCoupon-Details").find(".bBtnTab a").removeClass("active");
      $("#pageCoupon-Details")
        .find("#" + e.target.id)
        .addClass("active");
      $("#pageCoupon-Details").find("#tab2").show();
      $("#pageCoupon-Details").find("#tab1").hide();
    }
  };
  /* End Handle change tabs */

  /* Modal alert */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleOpenModalCouponAlert = (e) => {
    e.preventDefault();

    setIsModalShow(true);
  };

  const handleCloseModalCouponAlert = () => {
    setIsModalShow(false);
  };

  let _runCheckCouponUsed = 0;
  // console.log(_runCheckCouponUsed);

  const handleSubmitCouponAlert = (dataCouponAlert) => {
    // console.log("dataCouponAlert >>", dataCouponAlert);
    // console.log("couponCode >>", couponCode);

    if (dataCouponAlert === true && _runCheckCouponUsed === 0) {
      checkCouponUsed();

      _runCheckCouponUsed = 1;
      // console.log(_runCheckCouponUsed);
    }
  };
  /* End Modal alert */

  const [couponUsed, setCouponUsed] = useState([]);
  const [runNumber, setRunNumber] = useState(0);
  const [couponCode, setCouponCode] = useState(null);

  /* Check coupon used */
  const getCouponHistory = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CouponHistory({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCouponHistory resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCouponHistory >> ", e);
    }

    return _return;
  };

  const checkCouponUsed = () => {
    getCouponHistory({
      // custId: stateProfile.id,
      couponId: stateCoupon.id,
      genCoupon: true,
    }).then((_rsDataCouponHistory) => {
      // console.log("_rsDataCouponHistory >>", _rsDataCouponHistory);

      if (
        _rsDataCouponHistory.status === true &&
        _rsDataCouponHistory.result.length !== 0
      ) {
        setCouponUsed((prevArticleItem) => [
          ...prevArticleItem,
          _rsDataCouponHistory.result,
        ]);

        setRunNumber(_rsDataCouponHistory.result.length + 1);
      } else {
        setRunNumber(runNumber + 1);
      }
    });
  };
  /* End Check coupon used */

  /* Generate coupon code */
  const generateCouponCode = () => {
    let _initCouponCode = "G";

    _initCouponCode += stateCoupon.data.type;
    _initCouponCode += moment().format("YYYY");
    _initCouponCode += String(moment().format("M")).padStart(2, "0");
    _initCouponCode += String(moment().format("D")).padStart(2, "0");

    // _initCouponCode += String(runNumber).padStart(stateCoupon.data.uses_per_coupon.toString().length, "0");
    _initCouponCode += String(runNumber).padStart(4, "0");

    setCouponCode(_initCouponCode);
  };
  /* End Generate coupon code */

  /* Create coupon used */
  const postCreateCoupon = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CreateCoupon({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20201) {
        const resData = data.body;
        // console.log("postCreateCoupon resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "",
          _subTitle = "",
          _content = "";

        if (data.resultCode === 40401) {
          _title = `${t.couponDetailPage.modalCouponAlertMsg.code40401.title}`;
          _content = `${t.couponDetailPage.modalCouponAlertMsg.code40401.content}`;

          modalAlert.info({
            open: true,
            title: _title,
            subTitle: _subTitle,
            content: _content,
          });
        }
      }
    } catch (e) {
      console.log("ERR postCreateCoupon >> ", e);
    }

    return _return;
  };

  const createCouponUsed = () => {
    let payload = {
      custId: stateProfile.id,
      custNo: stateProfile.custNo,
      couponId: stateCoupon.id,
      usePoint: stateCoupon.data.point,
      couponCode: couponCode,
      qrExpired: stateCoupon.data.qr_expired,
      isUsed: "0",
    };

    postCreateCoupon(payload).then((_rsDataCreateCoupon) => {
      // console.log("_rsDataCreateCoupon >>", _rsDataCreateCoupon);

      if (
        _rsDataCreateCoupon.status === true &&
        _rsDataCreateCoupon.result.length !== 0
      ) {
        setDataCoupon({
          ...stateCoupon.data,
          couponCode: couponCode,
        });

        navigate("/coupon/barcode");
      }
    });
  };
  /* End Create coupon used */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile, stateCoupon);

    if (stateProfile.lineUid === null || stateProfile.custNo === null) {
      navigate("/");
    }

    if (stateCoupon.id === 0 || stateCoupon.data.length === 9) {
      navigate("/coupon");
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    if (runNumber > 0) {
      // console.log("couponUsed >>", couponUsed);
      // console.log("runNumber >>", runNumber);

      generateCouponCode();
    }
  }, [couponUsed, runNumber]);

  useEffect(() => {
    if (couponCode !== null) {
      // console.log("couponCode >>", couponCode);

      createCouponUsed();
    }
  }, [couponCode]);

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageCoupon-Details">
        <PageHeader />

        <main>
          <div className="wrapContent">
            <ModalCouponAlert
              open={isModalShow}
              onClose={handleCloseModalCouponAlert}
              submitCallback={handleSubmitCouponAlert}
            />

            <section id="pCouponDetails">
              <div className="bCouponDetails">
                <div className="bTitle">
                  <h1>
                    {t.lang === "en"
                      ? stateCoupon.data.name_en
                      : stateCoupon.data.name_th}{" "}
                    {t.couponDetailPage.detailForOrderAmount}{" "}
                    {Helper.FN.toNumberWithCommas(stateCoupon.data.min_total)}.-
                  </h1>
                  <p>
                    {t.couponDetailPage.detailValidTill}{" "}
                    {convertDateFormat(stateCoupon.data.to_date, "d/m/y")}
                  </p>
                </div>
                <div className="bPic">
                  <img
                    src={
                      stateCoupon.data.raw_image !== null
                        ? stateCoupon.data.raw_image
                        : icoCoupon
                    }
                    alt=""
                  />
                </div>
                <div className="bGroupTab">
                  <div className="bBtnTab">
                    <a
                      className="active"
                      id="btnTab1"
                      href="#"
                      onClick={handleChangeTab}
                    >
                      {t.couponDetailPage.tabDetail}
                    </a>
                    <a
                      className=""
                      id="btnTab2"
                      href="#"
                      onClick={handleChangeTab}
                    >
                      {t.couponDetailPage.tabCondition}
                    </a>
                  </div>
                  <div
                    className="bTabContent"
                    id="tab1"
                    dangerouslySetInnerHTML={{
                      __html:
                        t.lang === "en"
                          ? stateCoupon.data.description_en
                          : stateCoupon.data.description_th,
                    }}
                  ></div>
                  <div
                    className="bTabContent"
                    id="tab2"
                    dangerouslySetInnerHTML={{
                      __html:
                        t.lang === "en"
                          ? stateCoupon.data.condition_en
                          : stateCoupon.data.condition_th,
                    }}
                  ></div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button className="btn outline" onClick={handleGoBack}>
                  {t.btn.back}
                </button>
                <a
                  className="btn btnConfirm"
                  href="#"
                  onClick={handleOpenModalCouponAlert}
                >
                  {t.couponDetailPage.btnSubmit}
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default CouponDetail;
